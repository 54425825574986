import { faClock, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Alert from '../../../sections/ui/Alert';
import {useTranslation} from "react-i18next";

const OrderDetail = ({ order,cancelBooking, checked_in }) => {
  const countries = useSelector(state => state.countries.countries);
  const [country, setCountry] = useState();
  const {t, i18n} = useTranslation('common');
  const [suplement, setsuplement] = useState({})
  useEffect(() => {
    let addedsupplements={};
    let supplement_selected=order.supplement_json?JSON.parse(order.supplement_json):{};
    Object.keys(supplement_selected).forEach((key)=>{
      supplement_selected[key].forEach(item=>{
        if(Object.keys(addedsupplements).includes(item.id.toString()))
        {
          if((item.type==1 || item.type==3)&&item.value)
          {
          addedsupplements[item.id]["quantity"]+=1;
          if(item.type==3)
          addedsupplements[item.id]["values_other"].push(item.value)
          }
          if(item.type==2 && item.value.length!=0) 
          {
            item.value.forEach(newitem=>{
             let ind = addedsupplements[item.id]["value"].findIndex(val=>{
              return val.title==newitem.title;
            })
              if(ind!=-1)
              {
                addedsupplements[item.id]["value"][ind]["quantity"]+=1;
              }
              else
              {
                newitem["quantity"]=1;
                addedsupplements[item.id]["value"].push(newitem)
              }
            }) 
          }
        }
        else
        {
          if((item.type==1 || item.type==3)&&item.value)
          {
          item["quantity"]=1;
          if(item.type==3 && item.value)
           item["values_other"]=[item.value]
          else if(item.type==3)
           item["values_other"]=[]
          addedsupplements[item.id]=item
          }
  
  
          if(item.type==2 && item.value.length!=0) 
          {
            item.value.forEach(item=>{
              item["quantity"]=1;
            })
            addedsupplements[item.id]=item
          }
         
        }
      })
    })
    setsuplement(addedsupplements)
    if (countries) {
      let country = countries.find(
        country => country.id === order.event_country
      );
      setCountry(country);
    }
  }, [countries]);
  return (
    <div className='ticketDivdr'>
      <h6 className='b'>{order.event_title}&nbsp;<span style={{color:'red'}}>{order.booking_cancel==1?"("+t('PendingCancellation')+")":order.booking_cancel==2?"("+t('OrderCancelled')+")":order.booking_cancel==3?"("+t('OrderRefund')+")":''}</span></h6>
      <p>{t('CategoryPage.Category')} - {order.event_category}</p>
      <p className='text-sm mb-1 text-muted'>
        <FontAwesomeIcon icon={faClock} className='fa-fw' />{' '}
        {`${moment(
          order.event_start_date + ' ' + order.event_start_time
        ).format('dddd DD MMM YYYY HH:mm')}`}{' '}
        to{' '}
        {`${moment(order.event_end_date + ' ' + order.event_end_time).format(
          'dddd DD MMM YYYY HH:mm'
        )}`}
      </p>
      <p />
      {order.status ? (
        <>
        <div className='equality'>
        <a
          href={`https://www.simatours.myticketshop.be/backend/public/api/download/ticket/${order.id}/${order.order_number}`}>
          {t('DownloadTicket')}
        </a>
        {
          order.booking_cancel==0 &&
          <>
            <a
           onClick={()=>{cancelBooking(2)}}
          href="javascript:void(0)">
          {t('cancelled')}
        </a>
        <a 
        onClick={()=>{cancelBooking(3)} }
          href="javascript:void(0)">
          {t('refunded')}
        </a>
          </>
        }
       
          <a
           onClick={()=>{checked_in(order)}}
          href="javascript:void(0)">
          {t('checkedin')}
        </a>
       
        </div>
       
        </>
      ) : (
        <Alert type='warning'>{t('AttendeeDashoboardPage.TicketWarning')}</Alert>
      )}

      {order.event_state || order.event_city ? (
        <p className='text-sm text-muted'>
          {' '}
          <FontAwesomeIcon icon={faMapMarkerAlt} className='fa-fw' />
          {order.event_state}, {order.event_city}
          {country && `, ${country.country_name}`}
        </p>
      ) : (
        ''
      )}
      <p />
      <div className='table-responsive'>
        <table className='table table-hover table-condensed mb-1'>
          <tbody>
            <tr
              style={{
                width: '100%',
                backgroundColor: '#31c5f4',
                color: 'white'
              }}>
              <td>
                <h3>{t('AttendeeDashoboardPage.BookingDetails')}</h3>
              </td>
            </tr>
          </tbody>
        </table>
        <table className='table table-hover table-condensed mb-1'>
          <thead>
            <tr>
              <th>{t('AttendeeDashoboardPage.OrderNumber')}</th>
              <th>{t('AttendeeDashoboardPage.TotalAmountPaid')}</th>
              {order.transaction_details && (
                <>
                  <th>{t('AttendeeDashoboardPage.TransationNumber')}</th>
                  <th>{t('AttendeeDashoboardPage.PaymentStatus')}</th>
                  <th>{t('AttendeeDashoboardPage.PaidWith')}</th>
                  <th>{t('AttendeeDashoboardPage.OrderDate')}</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            <tr className='bg-gray'>
              <td className='border-top-white'>
                <div>{order.order_number.toString()}</div>
              </td>
              <td className='border-top-white'>
                <div>
                  €
                  {order.transaction_details
                    ? order.transaction_details.amount_paid
                    : order.net_price.replace('.', ',')}
                </div>
              </td>

              {order.transaction_details && (
                <>
                  <td className='border-top-white'>
                    <div>{order.transaction_details.txn_id}</div>
                  </td>
                  <td className='border-top-white'>
                    <div>{order.transaction_details.payment_status}</div>
                  </td>
                  <td className='border-top-white'>
                    <div>{order.transaction_details.payment_gateway}</div>
                  </td>
                  <td className='border-top-white'>
                    <div>
                      {moment(order.created_at).format('dddd DD MMM YYYY')}
                    </div>
                  </td>
                </>
              )}
            </tr>
          </tbody>
        </table>
      </div>

      <div className='table-responsive'>
        <table className='table table-hover table-condensed mb-1'>
          <tbody>
            <tr
              style={{
                width: '100%',
                backgroundColor: '#31c5f4',
                color: 'white'
              }}>
              <td>
                <h3>{t('AttendeeDashoboardPage.TicketDetails')}</h3>
              </td>
            </tr>
          </tbody>
        </table>
        <table className='table table-hover table-condensed mb-1'>
          <thead>
            <tr>
              <th className='border-top-white'>{t('AttendeeDashoboardPage.Title')}</th>
              <th>{t('TitleQty')}</th>
              <th>{t('Price')}</th>
              <th>{t('Subtotal')}</th>
              <th>{t('AttendeeDashoboardPage.Discount')}</th>
              <th>{t('AttendeeDashoboardPage.TotalPrice')}</th>
            </tr>
          </thead>
          <tbody>
            <tr className='bg-gray'>
              <td className='border-top-white'>
                <div>
                  {order.ticket_title}
                  {order.ticket_description}
                </div>
                <div className='clearfix'></div>
              </td>
              <td>
                <div className='b mt-1'>{order.quantity}</div>
              </td>
              <td>
                <div className='b mt-1'>€{order.ticket_price.replace('.', ',')}</div>
              </td>
              <td>
                <div className='b mt-1'>€{order.price.replace('.', ',')}</div>
              </td>
              <td>
                <div className='b mt-1'>
                  - €
                  {order.discount_price
                    ? order.discount_price.toFixed(2)
                    : '0.00'}
                </div>
              </td>
              <td>
                <div className='b mt-1'>
                  €{order.net_price ? order.net_price : '0.00'}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='table-responsive' style={{marginTop:'15px'}}>

        
{
   Object.keys(suplement).length>0 &&<>
  
<table className='table table-hover table-condensed mb-1' >
<tbody className='my-body'>
  <tr
  
    style={{
      width: '100%',
      backgroundColor: '#31c5f4',
      color: 'white'
    }}>
    <td className='my-row'>
      <h3>{t('TicketSuppliments')}</h3>
    </td>
  </tr>
</tbody>
</table>
<table className='table table-hover table-condensed mb-1'>
          <thead>
            <tr>
              <th className='border-top-white'>{t('SupplementTitle')}</th>
              <th>{t('TitleQty')}</th>
              <th>{t('Subtotal')}</th>
            </tr>
          </thead>
          <tbody>
            {
              Object.keys(suplement).map(item=>(
                <>
                {
                  (suplement[item].type==1 || suplement[item].type==3) &&
                  <tr className='bg-gray'>
                  <td className='border-top-white'>
                     <div style={{paddingLeft:'20px'}}>{suplement[item].title} {suplement[item].type==3? "( "+suplement[item].values_other.join(",") +" )":''}</div>
                   </td>
                  
                   <td className='border-top-white'>
                     <div style={{paddingLeft:'15px'}}>{suplement[item].quantity}</div>
                   </td>
                     <td className='border-top-white'>
                     <div style={{paddingLeft:'15px'}}>€{suplement[item].price*suplement[item].quantity}</div>
                   </td>
                 </tr>
                }
                 {
                  suplement[item].type==2 &&
                 suplement[item].value.map(newitem=>(
                  <tr className='bg-gray'>
                  <td className='border-top-white'>
                     <div style={{paddingLeft:'20px'}}>{suplement[item].title} ({newitem.title})</div>
                   </td>
                  
                   <td className='border-top-white'>
                     <div style={{paddingLeft:'15px'}}>{newitem.quantity}</div>
                   </td>
                     <td className='border-top-white'>
                     <div style={{paddingLeft:'15px'}}>€{newitem.price*newitem.quantity}</div>
                   </td>
                 </tr>
                 ))
                  
                }
                
                </>
               
              ))
            }
            
          </tbody>
        </table>
   </>
}

  </div>
    </div>
  );
};

export default OrderDetail;
