import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import Breadcrumbs from '../../sections/ui/Breadcrumbs'
import Card from '../../sections/ui/Card'
import { clearCart } from '../../service/actions/cartActions'
import { GET_PAYMENT_STATUS } from '../../APIConfig'
// import { useHistory } from 'react-router-dom'
import {useTranslation} from "react-i18next";


const loggedInUser = localStorage.getItem('user')

const OrderPending = ({ match }) => {
  const {t, i18n} = useTranslation('common');
  const dispatch = useDispatch()
  const orderId = match.params.orderNumber
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [Status, setStatus] = useState([]);

  const getPaymentStatus = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(GET_PAYMENT_STATUS + orderId
      );
      //setStatus(data.data)
      if(data && data.data && data.data.payment_status === 'success'){
        window.location.href = '/order/success/'+orderId;
      }
      else if(data && data.data && data.data.payment_status === 'failed'){
        window.location.href = '/order/failed/'+orderId;
      }
      else{
        setLoading(false);
      }
    } catch {
      setLoading(false);
    }
  };

  useEffect(() => {
    setInterval(()=> getPaymentStatus(), 20000);
  })

  useEffect(() => {
    dispatch(clearCart())
    localStorage.removeItem("customer_name");
    localStorage.removeItem("customer_phone");
    localStorage.removeItem("customer_email")
    localStorage.removeItem("customer_club");
    localStorage.removeItem("customer_birth");
    localStorage.removeItem("customer_invoice");
    localStorage.removeItem("customer_invoicedetail");
  }, [dispatch])

  return (
    <div>
      <Breadcrumbs active='' />
      <section className='section-content bg-white padding-y'>
        <div className='container'>
          <div className='row'>
            <main
              className='col-lg-12 order-1 order-lg-0 mt-4 mt-lg-0'
              style={{
                textAlign: 'center'
              }}
            >
              <Card>
                <h1>
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    color='yellow'
                    style={{ marginRight: 10 }}
                  />
                  {t('PaymentProcessing')}
                </h1>
                <br />
              </Card>
              <Card title='Details'>
                {orderId && <h3>{t('OrderNumber')}: #{orderId}</h3>}
                {
                  loggedInUser&&<>
                  <Link to='/dashboard/attendee/my-tickets'>{t('Header.MyTickets')}</Link>
                  </>
                }
              </Card>
              <Link to='/event-list/All'>{t('CartPage.ContinueShoppingButton')}</Link>
            </main>
          </div>
        </div>
      </section>
    </div>
  )
}

export default OrderPending;
