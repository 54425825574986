// import {
//   faHourglassHalf,
//   faMoneyCheck
// } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import CheckoutItem from '../../../sections/my-account/attendee/cart/CheckoutItem'
import Alert from '../../../sections/ui/Alert'
import Breadcrumbs from '../../../sections/ui/Breadcrumbs'
import Card from '../../../sections/ui/Card'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// import CartItemTotal from '../../../sections/my-account/attendee/cart/CartItemTotal'
import BillingInfoForm from './BillingInfoForm'
import MercCheckoutInfo from './MercCheckoutInfo'
import CheckoutPayment from './CheckoutPayment'
import CustomLoader from '../../../sections/ui/CustomLoader'
import CheckoutTotalSummary from './CheckoutTotalSummary'
import { API_DOMAIN } from '../../../Config'
import axios from 'axios'
// import { CHECKOUT_API } from '../../../APIConfig'
import { addToCart, clearCart } from '../../../service/actions/cartActions'
import { CART_RESET_ITEM } from '../../../service/constants/cartConstants'
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
const loggedInUser = localStorage.getItem('user')


export default function CheckOut(props) {
  const {t, i18n} = useTranslation('common');

  const cartItems = useSelector(state => state.cart.cartItems)
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [isPayAllowccv, setIsPayAllowccv] = useState(1)
  const [isPayAllowviva, setIsPayAllowviva] = useState(1)
  const [payNotification, setpayNotification] = useState('')
  //const alert = useAlert();
  const [newCartItems, setNewCartItems] = useState([])
  const [ticketTotal, setTicketTotal] = useState('0.00')
  const [totalTax, setTotalTax] = useState('0.00')
  const [totalPrice, setTotalPrice] = useState('0.00')
  const [totalDiscount, setTotalDiscount] = useState('0.00')
  const [couponApplied, setCouponApplied] = useState(false)
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [freeTicket, setFreeTicket] = useState(false)
  const [totalFee, setTotalFee] = useState();
  const [customFields, setcustomFields] = useState([]);
  const [transactionFee, setTransactionFee] = useState();
  const [supplementFee, setSuplementFee] = useState(0);
  const [paymentMethod, setpaymentMethod]= useState('2');
  const [brandtype, setBrandType]= useState('bcmc');
  const dispatch = useDispatch()

  useEffect(() => {
    if (!cartItems || cartItems.length <= 0) {
      history.push('/event-list/All')
    }
    const customer_name = localStorage.getItem("customer_name")
    const customer_email = localStorage.getItem("customer_email")
    if (!loggedInUser && !(customer_email && customer_name)) {
      history.push('/signin?redirect=/dashboard/attendee/checkout')
     return;
    }

    const calculateCartData = async () => {
      try{
      setLoading(true)
      const headers = loggedInUser?{
        Accept: 'application/json',
        Authorization: `Bearer ${JSON.parse(loggedInUser).access_token}`
      }:{}
      const updatedCartItems = cartItems.map(item => ({
        ticket_id: item.ticket_id,
        event_id: item.event_id,
        ticket_qty: item.ticket_qty,
        coupon_id: item.couponId,
        coupon_applied:item.couponData?item.couponData.applied_code:'',
        supplements:item.ticket.selected_supplement?item.ticket.selected_supplement:{}
      }))
      const cart_detail = JSON.stringify(updatedCartItems)
    
      const { data } = await axios.post(
        loggedInUser?`${API_DOMAIN}api/cart_detail_user`:`${API_DOMAIN}api/cart_detail`,
        { cart_detail , brand:brandtype},
        { headers }
      )
      
      const tData = data.data.ticket_detail.map(ticketItem => ({
        ticket: {
          id: ticketItem.ticket_id,
          title: ticketItem.ticket_title,
          totalTax: ticketItem.ticket_total_tax,
          quantity: ticketItem.ticket_qty,
          price: ticketItem.ticket_price,
          subTotal: ticketItem.ticket_subtotal,
          discountDetail: ticketItem.discount_detail,
          freeticket: ticketItem.free_ticket_get,
          selected_supplement:ticketItem.selected_supplement,
          supplements:ticketItem.supplements
        },
        event: ticketItem.event_details
      }))
     
      let overallTax = 0
      let overallTicketTotal = 0
      let overallDiscount = 0;
      let custom_field_data=[];
      for (let item of tData) {
        let resultthis=item.event.custom_allow&&item.event.custom_field_enabled==1&&item.event.custom_field_required==1
        custom_field_data.push({"custom_data":"","required":resultthis});
        overallTicketTotal =
          overallTicketTotal +
          Number(item.ticket.price) * Number(item.ticket.quantity)
        overallTax = overallTax + Number(item.ticket.totalTax)
        if (item.ticket.discountDetail) {
          overallDiscount =
            overallDiscount + Number(item.ticket.discountDetail.discount_amount)
        }
      }
      setcustomFields([...custom_field_data])
      
      
      setTotalTax(overallTax)
      setTicketTotal(overallTicketTotal)
      setNewCartItems(tData)
      setTotalPrice(data.data.total_price)
      setTotalDiscount(overallDiscount)
      setTotalFee(data.data.total_admin_org_fee)
      setSuplementFee(data.data.supplement_price)
      setTransactionFee(data.data.transaction_price)
      setIsPayAllowccv(data.data.isPayAllowccv)
      setIsPayAllowviva(data.data.isPayAllowviva)
      setpayNotification(data.data.payNotification)
      if (data.total_price <= 0 || Math.round(data.data.total_price) == 0) {
        setFreeTicket(true)
        setpaymentMethod('offline')
      }
      else{
        setFreeTicket(false)
        setpaymentMethod('2')
      }
      setLoading(false)
    }
    catch(e)
    {
      if(e.message.includes("401"))
      {
       localStorage.removeItem('user')
       history.push('/signin?redirect=/dashboard/attendee/checkout')
      }
    }
    }
    calculateCartData()
  }, [history, cartItems, couponApplied,brandtype])

  const updateCustomValue = (index,value) => {
    customFields[index].custom_data=value;
    setcustomFields([...customFields])

  }

  const getfreeticket = (ticket_id) =>
  {
    return newCartItems.find(res=>{
      return res.ticket.id == ticket_id;
    }).ticket.freeticket;
  }

  const checkoutHandler = async brandMethod => {
    let resultrequire = customFields.findIndex((item)=>{
      return item.required && !item.custom_data
    });
    if(resultrequire!=-1)
    {
      alert(t('fillallfields'));
      return 
    }
    
    const cartDetail = cartItems.map((item,index) => ({
      ticket_id: item.ticket_id,
      ticket_qty: item.ticket_qty,
      event_id: item.event_id,
      coupon_id: item.couponId,
      custom_field:customFields[index].custom_data,
      coupon_applied:item.couponData?item.couponData.applied_code:'',
      coupon_group:item.couponData?item.couponData.coupon_group_type:'',
      free_ticket_qty:getfreeticket(item.ticket_id),
      supplements:item.ticket.selected_supplement?item.ticket.selected_supplement:{}
    }))
    let reqestData = {};
    if(loggedInUser)
    {
    reqestData = {
      cart_detail: JSON.stringify(cartDetail),
      payment_method: paymentMethod,
      brand: brandMethod
    }
   }
   else
   {
   reqestData = {
      cart_detail: JSON.stringify(cartDetail),
      payment_method: paymentMethod,
      brand: brandMethod,
      customer_name:localStorage.getItem("customer_name"),
      customer_email:localStorage.getItem("customer_email"),
      customer_phone:localStorage.getItem("customer_phone"),
      customer_club:localStorage.getItem("customer_club"),
      customer_birth:localStorage.getItem("customer_birth"),
      customer_invoice:localStorage.getItem("customer_invoice"),
      customer_invoicedetail:localStorage.getItem("customer_invoicedetail"),
      customer_zip:"--",
      customer_gender:"--"
    }
   }

    let config = {}
    if(loggedInUser)
    {
      config = {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${JSON.parse(loggedInUser).access_token}`
        }
      }
    }
    setPaymentLoading(true)
    try {
      if (paymentMethod === '2' || paymentMethod === '0'|| paymentMethod === 'offline') {
        const { data } = await axios.post(
          loggedInUser?`${API_DOMAIN}api/checkout_now`:`${API_DOMAIN}api/checkout_now_iframe`,
          reqestData,
          config
        )
        
        if (data.status) {
         
          setError('')
          // payment method 2 is ccv
          if (paymentMethod === '2') {
            const ccv_payment_response = data.ccv_payment_response
            window.location = ccv_payment_response.payUrl
            history.push('/dashboard/attendee/checkout')
          } else if (paymentMethod === '1') {
            // payment method 1 is paypal
            alert(t('comingSoon'))
            return
          } else if (paymentMethod === '0'|| paymentMethod === 'offline') {
            // payment method 0 is for free tickets/total amount is 0
            // alert('coming soon...')
            history.replace(`/order/success/${data.data.order_number}`)
          } else {
            alert(t('PaymentMethodNotSupported'))
            return
          }
          // history.replace('/thankyou', { orderId: data.data.order_number })
        } else {
         
          setError(t('OrderNotCreated'))
         
        }
      } else {
        alert(t('comingSoon'))
        return
      }
    } catch (error) {
   
      setError(t('OrderNotCreated'))
      
    }
    setPaymentLoading(false)
  }

  const couponApply = (
    eventId,
    ticketId,
    ticket_qty,
    event,
    ticket,
    couponId,
    couponData
  ) => {
    dispatch(addToCart(eventId, ticketId, ticket_qty, event, ticket, couponId,couponData))
    setCouponApplied(true)
  }

  return (
    <>
      <Breadcrumbs active='Checkout' />
      <section className='section-content bg-white padding-y'>
        <div className='container'>
          <div className='row'>
            <main className='col-lg-8 order-1 order-lg-0 mt-4 mt-lg-0'>
              {error && <Alert type='error'>{error}</Alert>}
              <Card title={t('CheckoutPage.OrderSummary')}>
                <div className='pb-3 px-3 pt-1'>
                  <div className='row no-gutters'>
                    <div className='col-12 col-md-8'>
                      <p className='text-muted font-weight-bold m-0'>
                        {t('CartPage.Event_Ticket')}
                      </p>
                    </div>
                    <div className='col-12 col-md-4 d-none d-md-block'>
                      <div className='d-flex justify-content-between'>
                        <p className='text-muted font-weight-bold m-0'>{t('CartPage.Price')}</p>
                        <p className='text-muted font-weight-bold m-0'>
                        {t('CartPage.Quantity')}
                        </p>
                        {/* <p className='text-muted font-weight-bold m-0'>Tax</p> */}
                        <p className='text-muted font-weight-bold m-0'>
                        {t('CartPage.Subtotal')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {loading ? (
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <CustomLoader />
                  </span>
                ) : (
                  newCartItems &&
                  newCartItems.length > 0 &&
                  newCartItems.map((cartItem,index) => (
                    <CheckoutItem
                      title={cartItem.event.title}
                      link={`/event-details/${cartItem.event.slug}/${cartItem.event.id}`}
                      // image="/assets/uploads/events/5f9db4dc0d9c5696554595.jpg"
                      image={`/${cartItem.event.poster}`}
                      option={cartItem.ticket.title}
                      when={moment(
                        cartItem.event.start_date +
                          ' ' +
                          cartItem.event.start_time
                      ).format('dddd DD MMM YYYY HH:mm')}
                      where={`${cartItem.event.venue} ${cartItem.event.zipcode}, 
                            ${cartItem.event.city}`}
                      organizer={cartItem.event.user_detail.organisation}
                      key={cartItem.ticket.id + '-' + cartItem.event.id}
                      price={+cartItem.ticket.price}
                      quantity={cartItem.ticket.quantity}
                      subTotal={cartItem.ticket.subTotal}
                      tax={cartItem.ticket.totalTax}
                      ticketId={cartItem.ticket.id}
                      ticket={cartItem.ticket}
                      event={cartItem.event}
                      couponApply={couponApply}
                      index={index}
                      updateCustomValue={updateCustomValue}
                    />
                  ))
                )}
              </Card>
              <MercCheckoutInfo hide />
              <BillingInfoForm hide />
              {loading ? (
                <span
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <CustomLoader />
                </span>
              ) : (
                <>
                  {paymentLoading && (
                    <CustomLoader style={{ textAlign: 'center' }} />
                  )}
                  {!paymentLoading && (isPayAllowccv==1||isPayAllowviva==1) &&(
                    <CheckoutPayment
                      checkoutHandler={checkoutHandler}
                      setBrandType ={setBrandType}
                      brandtype={brandtype}
                      freeTicket={freeTicket}
                      totalFee = {totalFee}
                      isccv = {isPayAllowccv}
                      isviva = {isPayAllowviva}
                    />
                  )}
                  {!paymentLoading && (isPayAllowccv==0&&isPayAllowviva==0) &&(
                   <Card title={t('CheckoutPage.PaymentMethod')}>
                    <p className='pay-down'>{payNotification}</p>
                   </Card>
                  )}
                </>
              )}
            </main>
            {!loading && totalPrice && (
              <CheckoutTotalSummary
                total={totalPrice.toString().replace('.', ',')}
                ticketTotal={ticketTotal}
                totalTax={totalTax}
                totalDiscount={totalDiscount}
                totalFee = {totalFee}
                transactionFee = {transactionFee}
                supplementFee = {supplementFee}
              />
            )}
          </div>
        </div>
      </section>
    </>
  )
}
