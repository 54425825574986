import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Select from "react-select";
export default function CustomSelect(props) {
  return (
    <div className="form-group">
      {props.label ? (
        <label
          htmlFor={props.id}
          className={`${props.required ? "required" : ""} ${props.classNameLabel?props.classNameLabel:''}`}
        >
          {props.label}
        </label>
      ) : (
        ""
      )}

      {props.info ? (
        <small className="form-text text-muted mb-3">
          <FontAwesomeIcon icon={faInfoCircle} className="text-primary mr-1" />
          {props.info}
        </small>
      ) : (
        ""
      )}
      
      <Select
        id={props.id}
        className={`basic-single ${props.className}`}
        isMulti={props.isMulti}
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        name={props.name}
        Select={props.setValue}
        options={props.options}
        // {...props.register(props.name, { required: true, maxLength: 50 })}
        onChange={e => props.handleChange(e)}
        {...props}
      />
    </div>
  );
}
