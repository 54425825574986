import {API_DOMAIN} from './Config';
// https://www.simatours.myticketshop.be/backend/public/api/events/categories
export const CATEGORY_API = API_DOMAIN + 'api/event-categories';
export const BLOGS_API = API_DOMAIN + 'api/blogs';
export const VERIFY_ADMIN_USER = API_DOMAIN + 'api/auth/verify_admin';
export const ORGANIZERS_ALL_API = API_DOMAIN + 'api/auth/organizer-list';
export const EVENTS_ALL_API = API_DOMAIN + 'api/events/get_events';
export const SALES_EVENT_ALL = API_DOMAIN + 'api/bookings/get_sales';
export const GENDER_EVENT_ALL = API_DOMAIN + 'api/bookings/get_ratio';
export const SCAN_EVENT_ALL = API_DOMAIN + 'api/bookings/scan_ratio';
export const EVENT_ALL_GET = API_DOMAIN + 'api/bookings/region_ratio';
export const EVENTS_FILTER_API = API_DOMAIN + 'api/myevents/event_filter';
export const CREATE_EVENT_API = API_DOMAIN + 'api/myevents/create';
export const BLOGS_ALL_API = API_DOMAIN + 'api/blogs';
export const CONTACT_API = API_DOMAIN + 'api/contact';
export const SETTINGS_API = API_DOMAIN + 'api/settings';
export const BLOGS_BY_SLUG_API = API_DOMAIN + 'api/blogs/';
export const EVENT_BY_SLUG_API =  API_DOMAIN + 'api/events/';
export const PREVIEW_EVENT_API = API_DOMAIN + 'api/events/preview/'
export const EVENT_CATEGORIES_API = API_DOMAIN + 'api/events/categories';
export const BANNERS_API = API_DOMAIN + 'api/banners';
export const MY_PROFILE_API = API_DOMAIN + 'api/auth/me';
export const MY_PROFILE_UPDATE = API_DOMAIN + 'api/auth/update-profile';
export const GET_COUPONS_API = API_DOMAIN + 'api/coupon';
export const GET_Template_API_Event = API_DOMAIN + 'api/template/get_type_event';
export const GET_MASS_COUPONS_API = API_DOMAIN + 'api/coupon/masscoupon';
export const GET_MASS_COUPONS_CSV_API = API_DOMAIN + 'api/coupon/csv_masscoupon';
export const CREATE_TEMPLATE_API = API_DOMAIN + 'api/template/create_update';
export const GET_TEMPLATE_API = API_DOMAIN + 'api/template/get_type';
export const ADD_COUPON_API = API_DOMAIN + 'api/coupon/add_sinlge'
export const Update_COUPON_Group = API_DOMAIN + 'api/coupon/update_group'
export const DELETE_COUPON_API = API_DOMAIN + 'api/coupon/delete'
export const DELETE_TEMPLATE_API = API_DOMAIN + 'api/template/delete_type_event'

export const GET_ORGANIZER_EVENT_BY_ID = API_DOMAIN + 'api/myevents/all/';
export const GET_FRONTEND_PAGES = API_DOMAIN + 'api/pages';
export const FREE_EVENT_ALL = API_DOMAIN + 'api/free_tickets'
export const RESEND_TICKET = API_DOMAIN + 'api/resend-ticket-free'
//export const BACKEND_URL = 'https://ticketevent.deaninfo.com/';
export const TOP_EVENTS_API = API_DOMAIN + 'api/organiser/dashboard/top_ten';
export const EVENT_STAT = API_DOMAIN + 'api/organiser/dashboard/event_stat';
export const DASHBOARD_STAT = API_DOMAIN + 'api/myevents/event_summary';
export const EVENT_TICKET_ADD = API_DOMAIN + 'api/tickets/store';
export const EVENT_TICKETS_ALL = API_DOMAIN + 'api/tickets';
export const DELETE_TICKET_API = API_DOMAIN+ 'api/tickets/delete';

// checkout api urls
export const CHECKOUT_API = API_DOMAIN + 'api/checkout';
export const GET_PAYMENT_STATUS = API_DOMAIN + 'api/ccv-payment-status/';

export const DELETE_Voucher_API = API_DOMAIN + 'api/voucher/delete';
export const GET_Voucher_API = API_DOMAIN + 'api/voucher/get';
export const CREATE_Voucher_API = API_DOMAIN + 'api/voucher/create_edit';

export const DELETE_Supplement_API = API_DOMAIN + 'api/supplements/delete';
export const GET_Supplement_API = API_DOMAIN + 'api/supplements/get';
export const CREATE_Supplement_API = API_DOMAIN + 'api/supplements/create_edit';


export const BACKEND_URL = 'https://www.simatours.myticketshop.be/backend/public/storage/';
