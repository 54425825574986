import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import DatePicker from 'react-datepicker';

function LabeledInput(props) {
  return (
    <>
      <div className='form-group'>
        {props.label ? (
          <label
            htmlFor={props.id}
            className={`${props.required ? 'required' : ''} ${props.classNameLabel?props.classNameLabel:''}`}>
            {props.label}
          </label>
        ) : (
          ''
        )}

        {props.info ? (
          <small className='form-text text-muted mb-3'>
            <FontAwesomeIcon
              icon={faInfoCircle}
              className='text-primary mr-1'
            />
            {props.info}
          </small>
        ) : (
          ''
        )}
        {props.noInput ? (
          ''
        ) : props.textArea ? (
          <textarea
            type={props.type}
            id={props.id}
            name={props.name}
            required={props.required}
            className={`form-control ${props.className}`}
            value={props.value}
            placeholder={props.placeholder}
            autoComplete='off'
            {...props.register(props.name, { required: true, maxLength: 50 })}
          // onChange={(e) => onChangeHandler(e)}
          >
            {props.children}
          </textarea>
        ) : (
          <DatePicker
            dateFormat="dd/MM/yyyy"
            className='form-control'
            required={props.required}
            id={props.id}
            name={props.name}
            selected={props.startDate}
            onChange={date => props.setStartDate(date)}
            {...props}
          />
        )}
      </div>
    </>
  );
}
export default LabeledInput;
